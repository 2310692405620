import { LogLevel } from '@nts/std/utility';

export const environment = {
  production: true,
  baseAppUrl: 'https://ntssupportdeskservice.ntsinformatica.it',
  authenticationAppUrl: 'https://ntsloginservice.ntsinformatica.it/auth/login',
  prefixApi: '/api',
  envType: 'DEV',
  appTitle: 'SupportDeskService - DEV',
  appSubTitle: '',
  logLevel: LogLevel.All,
  isEnterpriseBarrierRequired: false
};
