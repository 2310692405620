import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { BaseRoutes, MainContainerComponent } from '@nts/std';
import { environment } from 'src/environments/environment';

const title = environment ? environment.appTitle : 'Application Title';

const routes: Routes = [

  // #region rotte con autenticazione
  ...BaseRoutes.getBaseAuthentictedRoutes(
    environment,
    [
      ...BaseRoutes.getEntityRoutes(
        title,
        'ticketcreationlongop',
        () => import('./ticket-creation-long-op/ticket-creation-long-op.module').then(m => m.TicketCreationLongOpModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'ticketslistlongop',
        () => import('./tickets-list-long-op/tickets-list-long-op.module').then(m => m.TicketsListLongOpModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'ticketeditmessagelongop',
        () => import('./ticket-edit-message-long-op/ticket-edit-message-long-op.module').then(m => m.TicketEditMessageLongOpModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'sdsconfiguration',
        () => import('./sds-configuration/sds-configuration.module').then(m => m.SDSConfigurationModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'ticketcommunications',
        () => import('./ticket-communications/ticket-communications.module').then(m => m.TicketCommunicationsModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'importfromreplicalongop',
        () => import('./import-from-replica-long-op/import-from-replica-long-op.module').then(m => m.ImportFromReplicaLongOpModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'ticket',
        () => import('./ticket/ticket.module').then(m => m.TicketModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'resendeventmessageslongop',
        () => import('./resend-event-messages-long-op/resend-event-messages-long-op.module').then(m => m.ResendEventMessagesLongOpModule)
      ),
      ...BaseRoutes.getDefaultRoutes(
        title,
        environment,
        true
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'emailblacklist',
        () => import('./email-black-list/email-black-list.module').then(m => m.EmailBlackListModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'aiaction',
        () => import('./ai-action/ai-action.module').then(m => m.AiActionModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'customercard',
        () => import('./customer-card/customer-card.module').then(m => m.CustomerCardModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'product',
        () => import('./product/product.module').then(m => m.ProductModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'busrelease',
        () => import('./bus-release/bus-release.module').then(m => m.BusReleaseModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'productrelease',
        () => import('./product-release/product-release.module').then(m => m.ProductReleaseModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'productsubrelease',
        () => import('./product-subrelease/product-subrelease.module').then(m => m.ProductSubreleaseModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'customercontracttype',
        () => import('./customer-contract-type/customer-contract-type.module').then(m => m.CustomerContractTypeModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'businesssector',
        () => import('./business-sector/business-sector.module').then(m => m.BusinessSectorModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'licenseholder',
        () => import('./license-holder/license-holder.module').then(m => m.LicenseHolderModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'commercialmodule',
        () => import('./commercial-module/commercial-module.module').then(m => m.CommercialModuleModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'customercontractrevision',
        () => import('./customer-contract-revision/customer-contract-revision.module').then(m => m.CustomerContractRevisionModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'contractdocumentation',
        () => import('./contract-documentation/contract-documentation.module').then(m => m.ContractDocumentationModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'customercontract',
        () => import('./customer-contract/customer-contract.module').then(m => m.CustomerContractModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'serialnumber',
        () => import('./serial-number/serial-number.module').then(m => m.SerialNumberModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'serialnumbermodule',
        () => import('./serial-number-module/serial-number-module.module').then(m => m.SerialNumberModuleModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'contractnotes',
        () => import('./contract-notes/contract-notes.module').then(m => m.ContractNotesModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'reserveddata',
        () => import('./reserved-data/reserved-data.module').then(m => m.ReservedDataModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'consuntiveactivitylongop',
        () => import('./consuntive-activity-long-op/consuntive-activity-long-op.module').then(m => m.ConsuntiveActivityLongOpModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'commercialarea',
        () => import('./commercial-area/commercial-area.module').then(m => m.CommercialAreaModule)
      ),
      ...BaseRoutes.getDefaultRoutes(
        title,
        environment,
        true
      ),
    ]
  ),
  // #endregion rotte con autenticazione

  // rotte base per la gestione degli errori
  ...BaseRoutes.getBaseErrorsRoute()
];

@NgModule({
  imports: [
    // TODO Tommy: si potrebbe creare una custom preloadin strategy per precaricare solo le rotte offline
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', paramsInheritanceStrategy: 'always', preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
